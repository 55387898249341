import React, { ReactElement, useRef, useEffect, useState, useCallback } from 'react'

import { Container } from './styles'

import bird from '../../components/Bird'
import background from '../../components/Background'
import floor from '../../components/Floor'
import menu from '../../components/Menu'
import pipes from '../../components/Pipes'
import score from '../../components/Score'
import endMenu from '../../components/EndMenu'
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../blockchain/metamaskConnector";
import GameAbi from "../../blockchain/abi/GameAbi.json";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import style from "./table.module.css";
import { CirclesWithBar, Triangle } from 'react-loader-spinner'
import { ethers } from 'ethers'
import Web3 from 'web3'

interface IScreen {
  renderCanvas: () => void;
  updateCanvas: () => void;
  click?: () => void;
}

interface IRank {
  address: string;
  score: number;
}

declare global {
  interface Window {
    currentScreen: IScreen;
    startScreen: IScreen;
    endScreen: IScreen
    score: number;
    die: boolean;
    account: string;
    rankList: any;
    gameCount: any
  }
}

window.score = 0;
window.bestScore = 0;
window.gameCount = 0;





export default function GamePage(): ReactElement {

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [canvasEl, setCanvas] = useState<HTMLCanvasElement | null>()
  const [context, setContext] = useState<CanvasRenderingContext2D | null>()
  const [gameSprite, setGameSprite] = useState<HTMLImageElement | null>()
  const [loading, setLoading] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 518, height: 540 });
  const [totalTransFee, setTotalTransFee] = useState<any>();
  const [gameContractState, setGameContract] = useState<any>(null);
  const [currentGasFeeTotal, setCurrentGasFeeTotal] = useState<number>(0)
  const [dieCount, setDieCount] = useState<number>(0)
  const [gameCount, setGameCount] = useState<number>(0)
  const totalGame = 5
  const [currentWindow, setCurrentWindow] = useState<any>()



  const [gameSounds, setGameSounds] = useState<HTMLAudioElement[] | null>(null)

  const [gamePrice, setGamePrice] = useState();
  const [clickExecuted, setClickExecuted] = useState(false);

  const [collided, setCollided] = useState(false)
  const [gameEnabled, setGameEnabled] = useState(false);
  const [highscore, setHighscore] = useState(0);
  const apiUrl = "https://flappyapi2.vercel.app/updateWinnerScore"
  const [rankList, setRankList] = useState([] as any);
  const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/644d36079ecd402a8c5a08271432d4aa");
  const etherProvider = new ethers.providers.EtherscanProvider('homestead', '1KTBVR2CSVGRYES8JBEHX4514TUM4CC5F2');


  const web3 = new Web3('https://mainnet.infura.io/v3/644d36079ecd402a8c5a08271432d4aa');


  const { active, account, library, activate, deactivate, chainId } =
    useWeb3React();
  const selectedNetwork = 1;

  let gameAddress = "0xB451e3869eD84acA802e01c7D83Ff4cED11540Bc";
  let gameContract: any;

  if (account && library) {
    gameContract = new library.eth.Contract(
      GameAbi,
      gameAddress
    );


  }

  useEffect(() => {
    window.account = account as any
  }, [account])

  useEffect(() => {
    window.rankList = rankList as any
  }, [rankList])

  useEffect(() => {
    setGameCount(window.gameCount)
  }, [window.gameCount])


  let frames = 0;

  const switchScreen = (newScreen: IScreen) => {
    window.currentScreen = newScreen
  }



  useEffect(() => {
    let canvas = canvasRef.current;
    let context = canvas?.getContext('2d');
    let sprites = new Image();
    let sounds: HTMLAudioElement[] = [];

    sprites.src = 'game.png';

    sounds[0] = new Audio();
    sounds[0].src = 'efeitos_caiu.wav'
    sounds[1] = new Audio();
    sounds[1].src = 'efeitos_hit.wav'
    sounds[2] = new Audio();
    sounds[2].src = 'efeitos_ponto.wav'
    sounds[3] = new Audio();
    sounds[3].src = 'efeitos_pulo.wav'

    sprites.onload = () => {
      setGameSounds(sounds)
      setGameSprite(sprites)
      setCanvas(canvas)
      setContext(context)
    }

  }, [])

  useEffect(() => {
    if (gameSprite && context && canvasEl && gameSounds) {

      let Bird = new bird(gameSprite as HTMLImageElement, context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)
      let Background = new background(gameSprite as HTMLImageElement, context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)
      let Floor = new floor(gameSprite as HTMLImageElement, context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)
      let Menu = new menu(gameSprite as HTMLImageElement, context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)
      let Pipes = new pipes(gameSprite as HTMLImageElement, context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)
      let Score = new score(context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)
      let EndMenu = new endMenu(gameSprite as HTMLImageElement, context as CanvasRenderingContext2D, canvasEl as HTMLCanvasElement)

      let gameScreen: IScreen = {
        renderCanvas: () => {
          Background.render();
          Bird.render();
          Pipes.render();
          Score.render();
          Floor.render();
        },
        click: () => {
          Bird.jumping(gameSounds[3])
        },
        updateCanvas: () => {
          Floor.update();
          Bird.update(Floor.y, gameSounds[1], gameCount);
          Bird.animation(frames);
          Bird.fallAnimation(frames);
          Pipes.update(frames, Bird.y, Bird.x, Bird.height, Bird.width, gameSounds[0], gameSounds[1], gameSounds[3], setGameCount);
          Score.update(frames, gameSounds[2]);
        }
      }

      let initScreen: IScreen = {
        renderCanvas: () => {
          Background.render();
          Floor.render();
          Menu.render();
          Bird.render();
        },
        click: () => {
          if (!loading && !clickExecuted) {
            switchScreen(gameScreen)
            Pipes.resetPipes()
            Bird.reset()
            window.gameCount = window.gameCount + 1
          }
        },
        updateCanvas: () => {
          Bird.animation(frames);
          Floor.update();
        }
      }

      let endScreen: IScreen = {
        renderCanvas: () => {
          Background.render();
          // Pipes.render();
          EndMenu.render(window.bestScore, window.score);
          Floor.render();
          Bird.render();
          if (window.gameCount === 5) {
            if (!loading && !clickExecuted) {
              setClickExecuted(true); // Set to true to prevent multiple executions
              setContract(window.bestScore, window.account, window.rankList)
            }
          }
          //  else {
          //   setGameCount(gameCount + 1)
          //   switchScreen(initScreen)
          //   Score.reset();
          //   Bird.reset();
          //   Pipes.resetPipes();
          //   Pipes.reset(null);
          //   console.log("asfawfsfawf")
          //   console.log("gamecount not 5", gameCount)
          // }
        },
        click: async () => {
          setClickExecuted(false)
          // Score.reset();
          // Bird.reset();
          // Pipes.resetPipes();
          // Pipes.reset(null);
          // switchScreen(initScreen)




        },
        updateCanvas: () => {
          Bird.update((Floor.y + 23), null, gameCount);
          // console.log("auhauhauhau")
        }
      }

      switchScreen(initScreen)
      window.startScreen = initScreen;
      window.endScreen = endScreen;
    }
  }, [gameSprite, context, canvasEl, gameSounds, clickExecuted])
  function isAddressInArray(addressToCheck: any, array: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].address.toLowerCase() === addressToCheck.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  const setContract = useCallback(async (bestScore, address, ranks) => {

    setGameEnabled(false);
    // console.log("justonetime2", bestScore, address, ranks)
    const loader = window.document.getElementById("endloader");

    if (loader) {
      loader.style.display = "flex";
      setLoading(true)
    }

    // console.log('highscore', highscore);
    // console.log('bestScore',ranks, bestScore, Number(rankList.length > 0 ? rankList.find((item: any) => item?.address === window.account)?.score : 0));

    if (Number(window.bestScore) > Number(ranks.length > 0 ? ranks.find((item: any) => item?.address === window.account)?.score : 0)) {
      // console.log('New highscore!');
      const data = { winnerScore: bestScore, winnerAddress: address };
      try {
        const res = await axios.post(apiUrl, data);
        // console.log(res);
        await provider.waitForTransaction(res.data.tx);
        setTimeout(() => {
          window.location.reload();
        }, 5000)
        setCollided(false);
      } catch (err) {
        // console.error(err);
        setCollided(false);
        setTimeout(() => {
          window.location.reload();
        }, 5000)
      }
    } else {
      if (!isAddressInArray(address, ranks)) {
        // console.log("buradakine girdi aq")
        const data = { winnerScore: bestScore, winnerAddress: address };
        try {
          const res = await axios.post(apiUrl, data);
          // console.log(res);
          await provider.waitForTransaction(res.data.tx);
          setTimeout(() => {
            window.location.reload();
          }, 5000)
          setCollided(false);
        } catch (err) {
          // console.error(err);
          setCollided(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000)
        }
      } else {
        if (loader) {
          // console.log("lowerthanhight")
          const prevHigh = ranks.find((item: any) => item?.address === window.account)?.score
          // console.log('prev highscore!', prevHigh);

          const data = { winnerScore: prevHigh, winnerAddress: address };
          try {
            const res = await axios.post(apiUrl, data);
            // console.log(res);
            await provider.waitForTransaction(res.data.tx);
            setTimeout(() => {
              window.location.reload();
            }, 5000)
            setCollided(false);
          } catch (err) {
            // console.error(err);
            setCollided(false);
            setTimeout(() => {
              window.location.reload();
            }, 5000)
          }
        }
      }
    }
  }, [account, rankList])


  useEffect(() => {
    if (currentWindow !== window.currentScreen) {
      setCurrentWindow(window.currentScreen)
    }
  }, [window.currentScreen])

  useEffect(() => {
    if (window.currentScreen && gameSprite && context) {
      looping()
    }
  }, [gameSprite, context])

  useEffect(() => {
    if (gameEnabled) {
      const unloadCallback = (event: any) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };
      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [gameEnabled]);

  // useEffect(() => {
  //   console.log(collided, !loading, collided && !loading)
  //   if (collided && !loading) {
  //   const fetchData = async () => {

  //       setLoading(true)
  //       const loader = window.document.getElementById("endloader");

  //       if (loader) {
  //         loader.style.display = "flex";
  //       }


  //       console.log('highscore', highscore);
  //       console.log('bestScore', window.bestScore);

  //       if (Number(window.bestScore) > Number(highscore)) {
  //         console.log('New highscore!');
  //         const data = { winnerScore: window.bestScore, winnerAddress: getWalletAbreviation(account!) };
  //         console.log(data);

  //         try {
  //           const res = await axios.post(apiUrl, data);
  //           console.log(res);
  //           await provider.waitForTransaction(res.data.tx);
  //           window.location.reload();
  //           setCollided(false)
  //           setLoading(false)
  //         } catch (err) {
  //           console.error(err);   
  //           setCollided(false)
  //           setLoading(false)
  //           // Handle error if needed
  //           // window.location.reload();
  //         }
  //       } else {
  //         if (loader) {
  //           loader.style.display = "none";
  //           window.location.reload();
  //         }
  //       }

  //   };

  //   fetchData(); // Call the async function immediately
  // }


  // }, [collided]);


  const looping = () => {
    context?.clearRect(0, 0, canvasEl?.width as number, canvasEl?.height as number);

    frames += 1
    window.currentScreen?.renderCanvas();
    window.currentScreen?.updateCanvas();

    requestAnimationFrame(looping)

  }

  const getGamePrice = async () => {
    return await gameContract.methods
      .playPrice()
      .call()
  };
  const isGameStarted = async () => {
    return await gameContract.methods
      .gameStarted()
      .call()
  };

  const getRanking = async () => {
    return gameContract.methods
      .getRanking()
      .call()
      .then((res: any) => {
        return res;
      });
  };

  useEffect(() => {
    if (!active) {
      connectMetamask();
    }

    if (chainId !== selectedNetwork) {
      switchNetwork();
    }

    if (account && library) {
      isGameStarted().then((res) => {
        // console.log("isGameStarted", res);
      });

      getGamePrice().then((res) => {
        // console.log("getGamePrice", res);
        setGamePrice(res);
      });

      getHighScore().then((res) => {
        // console.log(res)
        setHighscore(res)
      })

      getRanking().then((res1) => {
        // console.log("getRanking", res1);

        let rankList = [];
        for (let i = 0; i < res1.length; i++) {
          // console.log("res1[i]", res1[i]);

          rankList.push({
            address: res1[i][0],
            score: res1[i][1],
          });

        }


        rankList.sort((a, b) => {
          return b.score - a.score;
        });
        setRankList(rankList);


      });
    }
  }, [activate, chainId, account]);
  useEffect(() => {
    // console.log(rankList)
  }, [rankList])

  const toHex = (num: string) => {
    const val = Number(num);
    return "0x" + val.toString(16);
  };

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(selectedNetwork.toString()) }],
      });
    } catch (switchError: any) {
      // console.log(switchError);

      if (switchError.code === 4902) {
        //await addNetwork(selectedNetwork.toString());
      }
    }
  };


  async function connectMetamask() {
    try {
      if (
        window.ethereum &&
        window.ethereum.networkVersion !== selectedNetwork.toString()
      ) {
        switchNetwork();
      }

      await activate(injected, undefined, true);
      localStorage.setItem("isWalletConnected", "true");
      localStorage.setItem("connector", "injected");
    } catch (ex) {
      // console.log("Please install Metamask");
      // console.log(ex);
    }
  }

  function getWalletAbreviation(walletAddress: string) {
    if (walletAddress !== null && walletAddress !== undefined) {
      return walletAddress.slice(0, 6) + "..." + walletAddress.slice(-4);
    }
    return "";
  }

  async function disconnect() {
    try {
      deactivate();
      localStorage.setItem("isWalletConnected", "false");
      localStorage.removeItem("connector");
    } catch (ex) {
      // console.log(ex);
    }
  }

  const getHighScore = async () => {
    return gameContract.methods
      .highScore()
      .call()
      .then((res: any) => {
        // console.log("res", res);
        return res;
      });
  };

  function getRankEmoji(rank: number) {
    switch (rank) {
      case 1:
        return "🥇";
      case 2:
        return "🥈";
      case 3:
        return "🥉";
      default:
        return "";
    }
  }



  useEffect(() => {
    const updateCanvasDimensions = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Define your logic for determining canvas dimensions based on screen size
      // For example, you could set the canvas to 80% of the screen width and maintain the aspect ratio
      const newWidth = Math.min(screenWidth * 0.8, 518); // Adjust the 0.8 factor as needed
      const newHeight = (newWidth / 518) * 540; // Maintain the original aspect ratio

      setCanvasDimensions({ width: newWidth, height: newHeight });
    };

    // Initial dimensions setup
    updateCanvasDimensions();

    // Update dimensions when the window is resized
    window.addEventListener('resize', updateCanvasDimensions);

    return () => {
      window.removeEventListener('resize', updateCanvasDimensions);
    };
  }, []);


  // useEffect(() => {
  //   if(gameContract) {
  //     const fetchGas = async () => {
  //       console.log("insidefetch", gameContract)
  //       const res = await gameContract.methods.play().estimateGas({ from: account, value: gamePrice });
  //       const latestBlock = await provider.getBlock('latest');
  //       const gasPrice = web3.eth.getGasPrice()
  //       const blockGas = latestBlock.gasLimit 
  //       const finalGas = (Number((await gasPrice).toString()) * res);
  //       const finalGasInEther = web3.utils.fromWei(finalGas.toString(), 'ether');
  //       const hotone = Number((await provider.getFeeData()).maxPriorityFeePerGas?.toNumber())
  //       const hebele = Number((await provider.getFeeData()).maxFeePerGas?.toNumber()) + Number((await provider.getFeeData()).lastBaseFeePerGas?.toNumber())
  //       const thetotal = hotone + hebele/2
  //       console.log(finalGasInEther, Number(blockGas),(await provider.getFeeData()).maxPriorityFeePerGas?.toNumber(),(await provider.getFeeData()).lastBaseFeePerGas?.toNumber())
  //       console.log("actualtotal:", Number(web3.utils.fromWei((thetotal * Number(res)).toString(), 'ether')) * Number(await etherProvider.getEtherPrice()))
  //       setCurrentGasFeeTotal(Number(Number(web3.utils.fromWei((thetotal * Number(res)).toString(), 'ether')) + Number(gamePrice)) * Number(await etherProvider.getEtherPrice()))

  //       // web3.eth.getMaxPriorityFeePerGas().then((tip: any) => {
  //       //   web3.eth.getBlock("pending").then((block) => {
  //       //     const baseFee = Number(block.baseFeePerGas);
  //       //     const max = Number(tip) + baseFee - 1; // less than the sum


  //       // })})







  //     }
  //     fetchGas()
  //     console.log("asfasf2",gameContract)
  //   } else {
  //     // setGameContract(
  //     //   new library.eth.Contract(
  //     //     GameAbi,
  //     //     gameAddress
  //     //   )
  //     // )
  //   }
  // }, [gameContract])


  return (
    <Container className='display-flex' >
      <div
        id='endloader'
        style={{
          position: "absolute",
          height: "100%",
          width: "100vw",
          backdropFilter: "blur(10px)",
          display: "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          pointerEvents: "none",
          textShadow: "1px 1px 1px #000",
          userSelect: "none",
          zIndex: "999999",
          top: "0px"
        }}>
        Please wait when we calculate results <span style={{
          color: "red"
        }}>Don't close the page</span>
        <br></br>
        {Number(window.bestScore) > Number(rankList.length > 0 ? rankList.find((item: any) => item?.address === window.account)?.score : 0) ? (
          <>
            Congratulations! You beat your highest record!
            <br></br>
            Current score: {Number(window.bestScore)}
            <br></br>
            Your highest score: {Number(rankList.length > 0 ? rankList.find((item: any) => item?.address === window.account)?.score : 0)}
          </>
        ) : (
          <>
            {!isAddressInArray(window.account, rankList) ? (
              <>
                Congratulations! You beat your highest record!
                <br></br>
                Current score: {Number(window.bestScore)}
                <br></br>
                Your highest score: {Number(0)}
              </>
            ) : (
              <>
                Your socre lower then your highest score
                <br></br>
                Current score: {Number(window.bestScore)}
                <br></br>
                Your highest score: {Number(rankList.length > 0 ? rankList.find((item: any) => item?.address === window.account)?.score : 0)}
              </>
            )}
          </>
        )}
        <Triangle
          height="100"
          width="100"
          color="white"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='circles-with-bar-loading'
        />

      </div>
      <div
        id='txloader'
        style={{
          position: "absolute",
          height: "100%",
          width: "100vw",
          backdropFilter: "blur(10px)",
          display: "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          pointerEvents: "none",
          top: "0px",
          zIndex: "999999",
        }}>
        Please confirm transaction
        <Triangle
          height="100"
          width="100"
          color="white"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}

          ariaLabel='circles-with-bar-loading'
        />

      </div>
      <div
        style={{
          position: "relative",
          zIndex: "999999999999"
        }}
      >
        <Toaster
          position="top-right"

        />
      </div>
      <div className='heroouter'>
        <div className='canvasout'>
          <canvas
            style={{
              position: "relative",
              zIndex: "5",
            }}
            width={canvasDimensions.width}
            height={canvasDimensions.height} ref={canvasRef} onClick={async (e) => {
              const loader = window.document.getElementById("txloader")
              if (!loading) {
                if (!gameEnabled) {
                  if (loader) {
                    loader.style.display = "flex"
                    setLoading(true)
                  }

                  const playPromise = gameContract.methods
                    .play()
                    .send({ from: account, value: gamePrice });
                  // { from: account, value: gamePrice }
                  toast.promise(
                    playPromise,
                    {
                      loading: 'Transaction sent...',
                      success: 'Game started! \n \n If you leave, you will loose your Lives!',
                      error: (err) => err.message || 'Error',

                    },
                    { duration: 3000 }
                  );

                  await playPromise
                    .then((res: any) => {
                      setGameEnabled(true);
                      if (loader) {
                        loader.style.display = "none"
                        setLoading(false)
                      }
                    }, (err: any) => {
                      if (loader) {
                        setLoading(false)
                        loader.style.display = "none"
                      }
                    });

                  window.account = account!;
                }

                if (!gameEnabled) {
                  e.preventDefault();
                  return;
                }
                setGameEnabled(true)

                window.addEventListener('click', async () => {
                  if (window.currentScreen && window.currentScreen.click) {
                    window.currentScreen.click()
                  }
                })
              }



            }}>
          </canvas>

        </div>





      </div>
      <div style={{
        color: "white",
        padding: "10px",
        zIndex: "0",
        userSelect: "none",
        width: window.innerWidth < 600 ? "90%" : "518px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <div style={{
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <div
            style={{
              color: "white",

            }}
          >
            Lives left: {5 - gameCount}

          </div>
          <div
            style={{
              color: "white",

            }}
          >
            {/* Estimated Price Included Gas: {currentGasFeeTotal.toFixed(2)}$ */}


          </div>


        </div>
        <button
          onClick={active ? disconnect : connectMetamask}
          className='connectbutton'
          style={{
            color: "black", cursor: "pointer", marginTop: "20px", width: "100%"
          }}
        >
          {active && account
            ? `Connected: ${getWalletAbreviation(account!)}`
            : "Connect Metamask"}
        </button>
      </div>
      <div
        style={{
          userSelect: "none"
        }}
        className={style.rankContainer}>

        <h1 style={{ color: 'white' }}>Leaderboard</h1>

        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Wallet</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {rankList.map((row: any, index: any) => {
              return (
                <tr key={index}>
                  {<td>{index + 1}  {getRankEmoji(index + 1)}</td>}
                  <td>{`${row.address.slice(0, 5)}...${row.address.slice(-2)}`}</td>

                  <td>{row.score}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>



    </Container>
  )
}





/*
 getHighScore().then((res) => {
        console.log(res)
        console.log(highscore)
        if (Number(highscore) > Number(res)) {
          console.log('New highscore!')
          fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ winnerScore: highscore, winnerAddress: account }),
          })
            .then(response => response.json())
            .then(data => {
              console.log('Success:', data);
              toast.success('New highscore!')
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }
      })
*/