import { createGlobalStyle } from 'styled-components'

import { ITheme } from '../interfaces/mainInterfaces'

declare module 'styled-components' {
    interface DefaultTheme extends ITheme { }
}

export default createGlobalStyle`
    *{
        font-family: 'Poppins';
        color: ${props => props.theme.text};
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        outline: none;
        border: 0;
    }

    html, body{
        background: url('background.jpg');
        size: cover;
    }::-webkit-scrollbar {
        display: none;
      }

    a{
        text-decoration: none;
        color: ${props => props.theme.link}
    }

    .heroouter {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column ;
        padding-top: 30px;
        position:relative;

    }
    .canvasout {
        margin-bottom: 0px;
        width: 518px;
        height: 540px;
        position: relative;
        z-index: 99;
    }
    .connectbutton {
        margin: 20px;
        background: none;
        color: white !important;
        font-family: 'Poppins';
        font-weight: bolder;
        border: 2px solid yellow;
        padding :5px;
        border-radius: 30px
        

    }
    .connectbutton:hover {
        margin: 20px;
        background: none;
        color: white !important;
        font-family: 'Poppins';
        font-weight: bolder;
        border: 2px solid green;
        padding :5px;
        border-radius: 30px
        

    }

    .display-flex{
        flex-direction: column;
        display: flex;
        justify-content: space-evenly;
        align-items: center;        
    }
    .canvasin {
        width: 500px;
        height: 500px;
    }
    
    
    @media screen and (max-width: 992px) {
        canvas {
            width: 350px;
            height: 500px;
    }
        .canvasout {
            margin-bottom: 50px;
            width: 350px !important;
            height: 500px !important;
        }
      }
`
