class Menu{
  sprites: HTMLImageElement;
  context : CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;

  width = 174;
  height = 152;
  sourceX = 134;
  sourceY = 0;
  x = 0;
  y = 50;
  widthGo = window.innerWidth < 600 ? 100 : 174;
  heightGo = window.innerWidth < 600 ? 76 : 152;
  placeGo = window.innerWidth < 600 ? 2.8 : 1.7;



  constructor(sprites : HTMLImageElement, context : CanvasRenderingContext2D, canvas: HTMLCanvasElement){
    this.sprites = sprites
    this.context = context
    this.canvas = canvas
    this.x = (canvas.height / 2) - this.width / this.placeGo
  }

  render(){
    this.context.drawImage(this.sprites, this.sourceX, this.sourceY, this.width, this.height, this.x, this.y, this.widthGo, this.heightGo)
  }
}

export default Menu;
